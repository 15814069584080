@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@use 'three-dots';

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-display: swap;
  src: url("/public/fonts/AvenirLTProMedium.otf") format("truetype");
}

@font-face {
  font-family: "SF Compact Text";
  font-style: normal;
  font-display: swap;
  src: url("/public/fonts/FontsFree-Net-SFProText-Regular.ttf") format("truetype");
}

* {
  font-family: "SF Compact Text", sans-serif;
}

html {
  background: #1B1B1D;
  color: white;
}

body {
  font-family: inherit;
}

html,
body,
#__next {
  min-height: 100%;
  margin: 0px;
  background-repeat: no-repeat;
}

#root {
  overflow: hidden;
}

:root {
  --app-height: 100%;
}

.checkout,
.pioneer {
  height: 100vh;
  height: var(--app-height);
}

.pioneer::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

@media only screen and (min-width: 993px) {

  body,
  html {
    max-width: 100%;
  }
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  vertical-align: middle;
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}

ul {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
  margin-block-start: 0;
  -webkit-margin-after: 1em;
  margin-block-end: 0;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
  -webkit-padding-start: 28px;
  padding-inline-start: 28px;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button,
select {
  text-transform: none;
}

button {
  overflow: visible;
}

button:disabled {
  /* opacity: 0.5; */
  cursor: not-allowed !important;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

img {
  border: 0;
}

img,
video {
  max-width: 100%;
  height: auto;
}

input,
textarea {
  line-height: normal;
  background: transparent;
  border: none;
  outline: none;
}

a {
  outline: none;
}

input::placeholder {
  color: #888888;
}

*,
::before,
::after {
  box-sizing: border-box;
}

h1,
h2 {
  line-height: 110%;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

.gradient-text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.italicshadow {
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
}

.avatar-container>div>div>svg {
  display: none;
  pointer-events: none;
  user-select: none;
}

.dopple-card {
  transition: box-shadow 0.25s ease-in-out 0s, transform 0.25s ease 0s;
}

.dopple-card:hover {
  transform: translate(0px, -4px);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
}

.react-multi-email {
  min-height: 100px;
}

.react-multi-email,
.react-multi-email.focused {
  background: #171718;
  border: 1px solid #252528;
  border-radius: 5px;
  color: white;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.react-multi-email .chip {
  background: #6A7179;
  border-radius: 100px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.category-container::-webkit-scrollbar {
  height: 3px;
}

.category-container::-webkit-scrollbar-track {
  background: transparent;
}

.category-container::-webkit-scrollbar-thumb {
  background: #111112;
  border-radius: 5px;
}

.carousel-dots {
  display: flex !important;
  align-items: center;
  position: absolute;
  bottom: 30px;
  left: 86px;
  z-index: 2;
}

.carousel-dots>li+li {
  margin-left: 15px;
}

.carousel-dots li {
  margin: 0;
  width: auto;
  height: auto;
}

.carousel-dots li>button {
  padding: 0;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.25);
  transition: background-color 1s;
}

.carousel-dots li>button::before {
  display: none;
  color: white !important;
}

.carousel-dots-active>button {
  background-color: white !important;
}

.carousel-dots-active>button::before {
  opacity: 1 !important;
}

@media (max-width: 639px) {
  .carousel-dots {
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
  }

  .carousel-dots>li+li {
    margin-left: 10px;
  }

  .carousel-dots li>button {
    width: 5px;
    height: 5px;
  }
}

.shadow-text {
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.manage-container {
  height: -webkit-fill-available;
}

body::-webkit-scrollbar,
.manage-container::-webkit-scrollbar {
  display: none;
}

.gradient-button-padding-sm::before {
  padding: 1px;
}

.gradient-button-padding-md::before {
  padding: 2px;
}

.gradient-button-rounded-sm::before {
  border-radius: 5px;
}

.gradient-button-rounded-md::before {
  border-radius: 10px;
}

.gradient-button {
  z-index: 1;
  position: relative;
}

.gradient-button::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(46.83deg, #FF59D9 0%, #4DB4F5 100%) !important;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.gradient-button:hover::before {
  background: white !important;
}

.waitlist-container:hover::before {
  background: linear-gradient(46.83deg, #FF59D9 0%, #4DB4F5 100%) !important;
}

.waitlist-container input {
  -webkit-text-fill-color: #fff !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  caret-color: white !important;
}

.waitlist-container input::-webkit-input-placeholder{
  color:#6A7179 !important;
  -webkit-text-fill-color: #6A7179 !important;
}
.waitlist-container input:-moz-placeholder {
  color:#6A7179 !important;
  -webkit-text-fill-color: #6A7179 !important;
}

th {
  text-align: left;
}

th, td {
  width: max-content;
  white-space: nowrap;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before, .dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}

@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}

@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
}